@font-face {
    font-family: "hexa-icons";
    src: url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.eot?765e74e003a56f2a715e16c54c80504e?#iefix") format("embedded-opentype"),
        url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.woff2?765e74e003a56f2a715e16c54c80504e") format("woff2"),
        url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.woff?765e74e003a56f2a715e16c54c80504e") format("woff"),
        url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.ttf?765e74e003a56f2a715e16c54c80504e") format("truetype"),
        url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.svg?765e74e003a56f2a715e16c54c80504e#hexa-icons") format("svg");
}

i {
    line-height: 1;
}

i.icon-button[class^="icon-"] {
    font-size: 24px;
    line-height: 24px;
    vertical-align: text-top;
}

i.icon-button-small[class^="icon-"] {
    font-size: 20px;
    line-height: 20px;
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: hexa-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
    content: "\f101";
}
.icon-calendar:before {
    content: "\f102";
}
.icon-camera:before {
    content: "\f103";
}
.icon-circle:before {
    content: "\f104";
}
.icon-close:before {
    content: "\f105";
}
.icon-cloud_upload:before {
    content: "\f106";
}
.icon-conversion:before {
    content: "\f107";
}
.icon-copy:before {
    content: "\f108";
}
.icon-delete:before {
    content: "\f109";
}
.icon-down:before {
    content: "\f10a";
}
.icon-download:before {
    content: "\f10b";
}
.icon-edit:before {
    content: "\f10c";
}
.icon-edited:before {
    content: "\f10d";
}
.icon-eraser:before {
    content: "\f10e";
}
.icon-eye:before {
    content: "\f10f";
}
.icon-grid:before {
    content: "\f110";
}
.icon-impression:before {
    content: "\f111";
}
.icon-left:before {
    content: "\f112";
}
.icon-link_blank:before {
    content: "\f113";
}
.icon-link:before {
    content: "\f114";
}
.icon-list:before {
    content: "\f115";
}
.icon-lock_open:before {
    content: "\f116";
}
.icon-lock:before {
    content: "\f117";
}
.icon-magnifier:before {
    content: "\f118";
}
.icon-menu:before {
    content: "\f119";
}
.icon-not_loaded:before {
    content: "\f11a";
}
.icon-notification_tilt:before {
    content: "\f11b";
}
.icon-notification:before {
    content: "\f11c";
}
.icon-pin:before {
    content: "\f11d";
}
.icon-right:before {
    content: "\f11e";
}
.icon-screen:before {
    content: "\f11f";
}
.icon-settings:before {
    content: "\f120";
}
.icon-smartphone:before {
    content: "\f121";
}
.icon-square_check:before {
    content: "\f122";
}
.icon-square_minus:before {
    content: "\f123";
}
.icon-square:before {
    content: "\f124";
}
.icon-text:before {
    content: "\f125";
}
.icon-up:before {
    content: "\f126";
}
.icon-upload:before {
    content: "\f127";
}
.icon-v_check_circle:before {
    content: "\f128";
}
.icon-v_check:before {
    content: "\f129";
}
.icon-wheel_setings:before {
    content: "\f12a";
}

// @font-face {
//     font-family: "hexa-icons";
//     src: url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.eot?ccc0773181cf02f8def57419f802f890?#iefix") format("embedded-opentype"),
//         url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.woff2?ccc0773181cf02f8def57419f802f890") format("woff2"),
//         url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.woff?ccc0773181cf02f8def57419f802f890") format("woff"),
//         url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.ttf?ccc0773181cf02f8def57419f802f890") format("truetype"),
//         url("//cdn.hexa3d.io/hotlink-ok/fonts/hexa-icons.svg?ccc0773181cf02f8def57419f802f890#hexa-icons") format("svg");
// }

// i {
//     line-height: 1;
// }

// i[class^="icon-"]:before, i[class*=" icon-"]:before {
//     font-family: hexa-icons !important;
//     font-style: normal;
//     font-weight: normal !important;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 1;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// i.icon-button[class^="icon-"] {
//     font-size: 24px;
//     line-height: 24px;
//     vertical-align: text-top;
// }

// i.icon-button-small[class^="icon-"] {
//     font-size: 20px;
//     line-height: 20px;
// }

// .icon-alert:before {
//     content: "\f101";
// }
// .icon-calendar:before {
//     content: "\f102";
// }
// .icon-camera:before {
//     content: "\f103";
// }
// .icon-circle:before {
//     content: "\f104";
// }
// .icon-close:before {
//     content: "\f105";
// }
// .icon-cloud_upload:before {
//     content: "\f106";
// }
// .icon-copy:before {
//     content: "\f107";
// }
// .icon-delete:before {
//     content: "\f108";
// }
// .icon-down:before {
//     content: "\f109";
// }
// .icon-download:before {
//     content: "\f10a";
// }
// .icon-edit:before {
//     content: "\f10b";
// }
// .icon-edited:before {
//     content: "\f10c";
// }
// .icon-eraser:before {
//     content: "\f10d";
// }
// .icon-eye:before {
//     content: "\f10e";
// }
// .icon-grid:before {
//     content: "\f10f";
// }
// .icon-left:before {
//     content: "\f110";
// }
// .icon-link_blank:before {
//     content: "\f111";
// }
// .icon-link:before {
//     content: "\f112";
// }
// .icon-list:before {
//     content: "\f113";
// }
// .icon-lock_open:before {
//     content: "\f114";
// }
// .icon-lock:before {
//     content: "\f115";
// }
// .icon-magnifier:before {
//     content: "\f116";
// }
// .icon-menu:before {
//     content: "\f117";
// }
// .icon-notification_tilt:before {
//     content: "\f118";
// }
// .icon-notification:before {
//     content: "\f119";
// }
// .icon-pin:before {
//     content: "\f11a";
// }
// .icon-right:before {
//     content: "\f11b";
// }
// .icon-screen:before {
//     content: "\f11c";
// }
// .icon-settings:before {
//     content: "\f11d";
// }
// .icon-smartphone:before {
//     content: "\f11e";
// }
// .icon-square_check:before {
//     content: "\f11f";
// }
// .icon-square_minus:before {
//     content: "\f120";
// }
// .icon-square:before {
//     content: "\f121";
// }
// .icon-text:before {
//     content: "\f122";
// }
// .icon-up:before {
//     content: "\f123";
// }
// .icon-upload:before {
//     content: "\f124";
// }
// .icon-v_check_circle:before {
//     content: "\f125";
// }
// .icon-v_check:before {
//     content: "\f126";
// }
// .icon-wheel_setings:before {
//     content: "\f127";
// }
