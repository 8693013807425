@import "./app-theme";
@import "./globals";
@import "./hexa-icons";

body {
    margin: 0;
    // min-height: 98vh;
    min-height: 100svh;
    overflow: hidden;
    background: $body-background;
    font-family: $font-family;
    // font-size: 20px;

    &.stg,
    &.qa,
    &.dev {
        &::before {
            position: absolute;
            top: -20px;
            left: -40px;
            transform: rotateZ(-45deg);
            padding: 35px 35px 5px;
            color: $box-background;
        }
    }
    &.stg::before {
        content: "STG";
        background-color: $warn;
    }
    &.qa::before {
        content: "QA";
        background-color: $warn-accent;
    }
    &.dev::before {
        content: "DEV";
        background-color: $tertiary;
    }
    .mat-mdc-raised-button:not(:disabled):not(.second_button):not(.outline):not(.third_button) {
        color: $contrast-text;
    }
}
body .mdc-tab__text-label,
body .mat-tab-link {
    font-family: $font-family;
}

body.offline::after {
    position: absolute;
    content: "Offline";
    top: -20px;
    right: -40px;
    transform: rotateZ(45deg);
    background-color: $warn;
    padding: 35px 35px 5px;
    color: $box-background;
    z-index: -1;
}
.dir-dir {
    direction: ltr;
}
.dir-op-dir {
    direction: rtl;
}
.float-dir {
    float: left;
}
.float-op-dir {
    float: right;
}
body .mat-mdc-snack-bar-container {
    color: $contrast-text;
    border-radius: 6px;
    background-color: $brand;
    max-width: 407px;
    simple-snack-bar {
        font-family: $font-family;
    }
    .mdc-button-wrapper {
        font-weight: bold;
    }
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        color: $contrast-text;
    }
}
.mdc-button .mdc-button-wrapper,
.mat-raised-button .mdc-button-wrapper,
body .mat-raised-button:not([class*="mat-elevation-z"]),
body .mat-mdc-mini-fab:not([class*="mat-elevation-z"]) {
    letter-spacing: 1px;
    font-family: $font-family;
    box-shadow: none;
}

body {
    mat-form-field.hide-subscript {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
    mat-form-field.no-padd, .no-padd-children {
        .mdc-text-field--filled, .mdc-text-field {
            padding-left: 4px;
            padding-right: 4px;
        }
        .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
            padding: .4375em 0;
            min-height: unset;
        }
        .mat-mdc-form-field-infix {
            padding-top: 20px;
            min-height: unset;
            padding-bottom: 4px;
        }
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
                .mat-mdc-floating-label {
                    top: 24px;
                }
            }
        }
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }
    .contrast-wrap {
        mat-form-field {
            .mat-mdc-text-field-wrapper {
                .mat-mdc-form-field-flex {
                    .mat-mdc-floating-label {
                        color: $contrast-text;
                    }
                }
            }
        }
    }
    mat-form-field.mat-mdc-form-field.contrast,
    mat-form-field.contrast,
    app-autocomplete.contrast mat-form-field.mat-mdc-form-field {
        color: $contrast-text;
        .mat-mdc-form-field-label, .mat-mdc-select-placeholder {
            color: $contrast-text;
        }
        .mat-mdc-form-field-underline {
            background-color: $contrast-text;
        }
        .mat-hint {
            color: $contrast-text;
        }
        .mat-mdc-select-arrow,
        .mat-mdc-select-value {
            color: $contrast-text;
        }
        .mdc-line-ripple::before {
            border-bottom-color: $contrast-text;
            transition-duration: $default-duration;
            transition-property: border-bottom-color;
        }
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover {
        .mdc-line-ripple::before {
            border-bottom-color: $lead3;
        }
    }
}

.small-text,
.mat-mdc-select.small-text .mat-mdc-select-value-text,
.small-text.mat-mdc-option .mdc-list-item__primary-text,
.small-text .mat-mdc-form-field, .small-text .mat-mdc-select {
    --mdc-filled-text-field-label-text-size: #{$def-font-size};
    --mat-option-label-text-size: #{$def-font-size};
    font-size: $def-font-size;
}

.mat-mdc-select.small-text .mat-mdc-select-value-text {
    font-size: calc($def-font-size - 1px);
}

.small-text .mat-mdc-form-field-subscript-wrapper {
    // display: none;
    height: 10px;
}

mat-option {
    --mat-option-label-text-size: $def-font-size;
    min-height: 40px;
}

.invert-filter {
    filter: invert(1);
}

.center-me,
.mdc-button.center-me,
.mdc-fab.center-me,
.mdc-icon-button.center-me,
.mat-mdc-mini-fab.center-me,
.mat-raised-button.center-me,
.mat-mdc-select.center-me {
    display: block;
    margin: auto;
}
.mdc-button.tertiary,
.mat-raised-button.tertiary,
.tertiary.mat-mdc-mini-fab {
    background-color: $tertiary;
    color: $contrast-color;
}
// .tag-chip-list-wrap.mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
//     background-color: $contrast-text;
//     display: none;
// }
.mat-raised-button.thin {
    line-height: 32px;
    font-size: 13px;
    font-weight: normal;
}

.mat-mdc-mini-fab.thin {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 13px;
    font-weight: normal;
    .mdc-button-wrapper {
        line-height: 16px;
        color: $contrast-text;
    }
}

.mat-raised-button.thinner,
.mdc-button.thinner {
    line-height: 28px;
    font-size: 13px;
    font-weight: normal;
}

.mat-raised-button.thinnest,
.mdc-button.thinnest {
    line-height: 23px;
    font-size: 10px;
    font-weight: normal;
}

.mat-raised-button.invert:not(.mat-accent) {
    background-color: $box-background;
    color: $contrast-color;
}

.mat-raised-button.invert {
    background-color: $contrast-color;
    color: $box-background;
}

.mat-raised-button.invert.primary:not(.mat-accent) {
    background-color: transparent;
    color: $primary;
    border: solid 1px $primary;
}

.mdc-button.outline:not(.mat-accent),
.mat-raised-button.outline:not(.mat-accent),
.mdc-fab-button.outline:not(.mat-accent),
.mat-mdc-mini-fab.outline:not(.mat-accent) {
    background-color: transparent;
    color: $contrast-color;
    border: solid 1px $brand;
    line-height: 34px;
}

.mdc-button.outline:not(.mat-accent).active,
.mat-raised-button.outline:not(.mat-accent).active,
.mdc-fab-button.outline:not(.mat-accent).active,
.mat-mdc-mini-fab.outline:not(.mat-accent).active {
    background-color: $contrast-color;
    color: $box-background;
}

body .mat-mdc-mini-fab.outline:not(.mat-accent) {
    line-height: 32px;
}

.mdc-icon-button.invert:not(.mat-accent) {
    border: solid 1px $brand;
    mat-icon {
        color: $brand;
    }
}

.mat-raised-button.mat-accent.invert {
    border: solid 1px $box-background;
}

.mat-raised-button.no-padd,
.mdc-button.no-padd {
    padding: 0;
}

.mat-calendar-body-selected {
    background-color: $brand;
    color: $contrast-text;
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: $secondary;
}
snack-bar-container.success {
    simple-snack-bar {
        .mat-simple-snackbar-action {
            color: $primary;
        }
    }
}
snack-bar-container.error {
    simple-snack-bar {
        .mat-simple-snackbar-action {
            color: $warn3;
        }
    }
}
snack-bar-container.info {
    simple-snack-bar {
        .mat-simple-snackbar-action {
            color: $body-background;
        }
    }
}
//loader
body mat-progress-bar.main-progress-bar {
    position: fixed;
    z-index: 300;
    top: 0;
}
body .mat-progress-bar-fill::after {
    background-color: $lead2;
}

body .mat-progress-bar-buffer {
    background: $lead2;
    opacity: 0.4;
}

body .mat-progress-bar {
    border-radius: 2px;
    rect {
        fill: none;
    }
}
body .mat-progress-bar-background {
    fill: $lead2;
}

body .mat-mdc-form-field.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label,
.mat-mdc-form-field.mat-mdc-form-field-can-float
    .mat-input-server:focus
    + .mat-mdc-form-field-label-wrapper
    .mat-mdc-form-field-label {
    color: $brand;
    font-size: 9px;
    transform: translateY(-1.28125em);
}
body
    .mat-mdc-form-field.mat-mdc-form-field-can-float
    .mat-mdc-form-field-autofill-control:-webkit-autofill
    + .mat-mdc-form-field-label-wrapper
    .mat-mdc-form-field-label {
    color: $brand;
    font-size: 9px;
    transform: translateY(-1.28125em);
}
.def-dur {
    transition-duration: $default-duration;
}
.opacity {
    opacity: 0;
}
.hidden {
    visibility: hidden;
}
[hidden],
.mat-mdc-list .mat-mdc-list[hidden],
.mdc-icon-button[hidden] {
    opacity: 0;
    transform: scale(0);
}
.content-va {
    content-visibility: auto;
}
.warn,
.mat-mdc-form-field .mat-hint.warn,
.text2.warn {
    color: $warn;
}
.warn-bg,
.third_button.warn-bg {
    background-color: $warn;
}
.warn2,
.mat-mdc-form-field .mat-hint.warn2 {
    color: $warn2;
}
.warn2-bg,
.third_button.warn2-bg {
    background-color: $warn2;
}
.primary {
    color: $primary;
}
.secondary {
    color: $secondary;
}
.text-center {
    text-align: center;
}
.text-dir {
    text-align: left;
}
.text-op-dir {
    text-align: right;
}
.flex-wrap-wrap {
    flex-wrap: wrap;
    .scrollable-content {
        flex-wrap: wrap;
    }
}
.bold {
    font-weight: bold;
}
.uppercase,
.h1.uppercase {
    text-transform: uppercase;
}
.lowercase {
    text-transform: lowercase;
}
.capitalize,
.capitalize .mdc-button-wrapper {
    text-transform: capitalize;
}
.underline {
    text-decoration: underline;
}
.italic {
    font-style: italic;
}
.gray,
.gray.text2 {
    color: $gray;
}
.gray2 {
    color: $gray2;
}
.pointer {
    cursor: pointer;
}
.vertical-align {
    transform: translateY(-50%);
    top: 50%;
    position: relative;
}
.va-sub {
    vertical-align: sub;
}
.va-super {
    vertical-align: super;
}
.va-middle {
    vertical-align: middle;
}
.va-top {
    vertical-align: top;
}
.va-bottom {
    vertical-align: bottom;
}
.pos-rel {
    position: relative;
}
.pos-abs,
.mdc-button.pos-abs,
.mdc-icon-button.pos-abs {
    position: absolute;
}
.disp-block {
    display: block;
}
.disp-ib {
    display: inline-block;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
.w-100,
.w-100.title {
    width: 100%;
}
.max-w-100 {
    max-width: 100%;
}
.h-100 {
    height: 100%;
}
.max-h-100 {
    max-height: 100%;
}
.link {
    text-decoration: underline;
}
.color-link {
    color: $lead3;
}
.color-link-2 {
    color: #2d12d6;
}
.link1 {
    text-decoration: underline;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: $contrast-color;
}
.link1.no-ul,
.no-ul {
    text-decoration: unset;
}
.link2 {
    text-decoration: underline;
    font-size: 14px;
    color: $gray2;
}
.link2.no-ul {
    text-decoration: unset;
}
.letter-spacing-1 {
    letter-spacing: 1px;
}
.letter-spacing-05 {
    letter-spacing: 0.5px;
}
// body .mdc-button, body .mat-raised-button, body .mdc-fab, body .mat-mdc-mini-fab, body .mat-sort-header-button, body .mat-sort-header-content {
//     text-transform: uppercase;
//     border-radius: 50px;
// }
mat-icon.material-icons.small {
    width: 18px;
    height: 18px;
    font-size: 17px;
    line-height: 17px;
}
.mdc-icon-button.img-icon-button {
    img {
        width: 40px;
        object-fit: contain;
    }
}
.mdc-icon-button.small {
    width: 36px;
    height: 36px;
    mat-icon {
        font-size: 20px;
        line-height: 20px;
    }
    i[class^="icon-"] {
        font-size: 16px;
    }
}
.mdc-icon-button.gray {
    color: $gray;
}
body .mat-ink-bar {
    height: 5px;
}
body .mat-sort-header-button,
body .mat-sort-header-content {
    font-size: 0.8em;
}
@media (max-width: $sm-threshold) {
    body .mat-sort-header-button,
    body .mat-sort-header-content {
        font-size: 0.7em;
    }
}
.box-shadow {
    box-shadow:
        0 0px 16px 0px $shadow-color,
        0 0px 16px 0px $shadow-color,
        0 0px 16px 0px $shadow-color,
        0 0px 16px 0px $shadow-color;
    .bottom-box::after {
        bottom: -5px;
        left: -5px;
    }
    .bottom-box::before {
        bottom: -5px;
        right: -5px;
    }
}
.box-shadow::after,
.box-shadow::before,
.box-shadow .bottom-box::after,
.box-shadow .bottom-box::before {
    position: absolute;
    width: 1px;
    height: 1px;
    /* box-shadow: 0 1px 10px 4px $shadow-color;*/
    content: "";
    background-color: $shadow-color;
}
.box-shadow::after {
    top: -5px;
    left: -5px;
}
.box-shadow::before {
    top: -5px;
    right: -5px;
}
.min-button-width,
.mat-raised-button.min-button-width {
    min-width: 192px;
}
.min-thin-button-width,
.mat-raised-button.min-thin-button-width {
    min-width: 120px;
}
// body .mdc-checkbox-checkmark-path {
//     stroke: #000 !important;
// }
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
    color: $brand;
}
// .mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
//     background-color: red;
//   }
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: $secondary;
}
body .mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $secondary;
    border-radius: 3px;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: $primary-hover;
}

.chip-list-wrap.mat-mdc-form-field:not(.mat-focused) .mat-mdc-form-field-label {
    color: $contrast-text;
    font-size: 14px;
}
.push_pin {
    transform: rotate(-45deg);
    padding-left: 4px;
    padding-bottom: 4px;
    color: $contrast-text;
    top: -10px;
    position: relative;
    left: -10px;
}
.title {
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid #eee;
    padding-bottom: 17px;
    width: calc(100% - 84px);
    margin: 0 auto 15px;
    top: 24px;
    position: relative;
    letter-spacing: -0.5px;
}
.title.no-bb {
    border-bottom: none;
}
body {
    app-file-upload.w-100 {
        .wrap.drop {
            button.mdc-icon-button {
                width: 100%;
            }
        }
    }
}
.file-image {
    app-file-upload.accent {
        button.mdc-icon-button.mat-accent {
            background-color: $accent;
            color: $box-background;
        }
    }
    app-file-upload.primary {
        button.mdc-icon-button.mat-accent {
            background-color: $primary;
            color: $box-background;
        }
    }
    app-file-upload.secondary {
        button.mdc-icon-button.mat-accent {
            background-color: $secondary;
            color: $box-background;
        }
    }
    .logo-wrap {
        width: $file-diameter;
        height: $file-diameter;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        img {
            max-width: $file-diameter;
            max-height: $file-diameter;
            border-radius: 50%;
        }
        .img {
            display: inline-block;
            width: $file-diameter;
            height: $file-diameter;
            border-radius: 50%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        button.delete-image.mdc-button {
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition-duration: $default-duration;
            background-color: rgba(0, 0, 0, 0.2);
            width: 100%;
            z-index: 1;
            padding: 0;
            border-radius: 0;
            mat-icon {
                color: $box-background;
            }
        }
    }
    .logo-wrap:hover {
        button.delete-image.mdc-button {
            opacity: 1;
        }
    }
}
mat-tree.contrast {
    background-color: $contrast-color;
    color: $contrast-text;
    .mat-mdc-checkbox {
        .mdc-form-field {
            color: $contrast-text;
        }
    }
    .mat-icon {
        color: $primary;
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border-color: $on-button;
    }
    .mdc-checkbox-label {
        color: $contrast-text;
        font-size: 13px;
        text-transform: capitalize;
        letter-spacing: 0.3px;
    }
    .mdc-checkbox-background {
        border: solid 2px $contrast-text;
        border-color: $contrast-text;
        animation: none;
    }
    .mdc-checkbox-checked {
        .mdc-checkbox-background {
            border: solid 2px $lead4;
            background-color: $lead4;
        }
    }
    .mdc-checkbox-indeterminate.mat-primary {
        .mdc-checkbox-background {
            background-color: $lead4;
        }
    }
    .mdc-checkbox-anim-checked-unchecked .mdc-checkbox-background {
        .mdc-checkbox-checkmark {
            fill: $brand;
        }
    }
    .mdc-checkbox-checkmark {
        fill: $brand;
    }
    .mdc-checkbox-checkmark-path {
        stroke: $brand !important;
        stroke-width: 3.2px;
    }
    .mat-tree-node {
        min-height: 40px;
    }
}

*:not(html):not(body)::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 $scrollbar-width rgba(0, 0, 0, 0.3);
    background-color: $gray4;
}

*:not(html):not(body)::-webkit-scrollbar {
    width: $scrollbar-width;
    height: $scrollbar-width;
    background-color: $gray4;
}

*:not(html):not(body)::-webkit-scrollbar-thumb {
    background-color: $gray3;
}

.padd-sides {
    padding-left: $default-padd;
    padding-right: $default-padd;
}

app-product-request {
    .router-outlet-wrap {
        app-feedback-edit {
            height: 100%;
            display: block;
        }
    }
}

.mat-mdc-tab-body-wrapper {
    height: 100%;
}
mat-tab-group.no-min-w {
    .mdc-tab__text-label {
        min-width: unset;
    }
}
mat-tab-group.no-min-w.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0;
}
mat-tab-group.uppercase {
    .mdc-tab__text-label {
        text-transform: uppercase;
    }
}
mat-tab-group.no-underline {
    .mat-tab-nav-bar,
    .mat-mdc-tab-header {
        border-bottom: none;
    }
    .mdc-tab--active {
        font-weight: bold;
    }
}
mat-tab-group.small-text {
    .mdc-tab__text-label {
        font-size: 11px;
    }
    .mdc-tab {
        padding-left: 0;
        padding-right: 0;
    }
    .mat-mdc-tab-labels {
        gap: 10px;
    }
}
mat-tab-group.small-text.double-gap {
    .mat-mdc-tab-labels {
        gap: 20px;
    }
}
mat-tab-group.big-text {
    .mdc-tab__text-label {
        font-size: 16px;
    }
    mat-tab-group.small-text {
        .mdc-tab__text-label {
            font-size: 11px;
        }
        .mdc-tab {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
mat-tab-group.h-100 {
    .mat-tab-body-wrapper {
        height: 100%;
    }
}
// mat-tab-group.mat-mdc-tab-group.customized {
//     .mat-mdc-tab.mdc-tab--active {
//         .mdc-tab__text-label {
//             color: $secondary;
//         }
//     }
// }
mat-tab-groupmat-tab-group.mat-mdc-tab-group.customized.h-70 {
    .mdc-tab__text-label {
        height: 70px;
    }
}
mat-tab-group.mat-mdc-tab-group.customized {
    .mdc-tab__text-label-active {
        opacity: 1;
        .mdc-tab__text-label {
            color: $contrast-color;
            font-weight: 600;
        }
    }
    .mdc-tab__text-label {
        height: 40px;
    }
}
// .mdc-tab-indicator__content--underline 
mat-tab-group.mat-mdc-tab-group.customized .mat-mdc-tab {
    // --mdc-tab-indicator-active-indicator-color: #{$secondary};
    // --mat-tab-header-active-label-text-color: #{$secondary};
    .mdc-tab-indicator__content--underline {
        border-color: $secondary;
    }
    &.mdc-tab--active {
        .mdc-tab__text-label {
            color: $contrast-color;
        }
    }
}

mat-tab-group.mat-mdc-tab-group.customized.mat-primary {
    .mdc-tab__text-label:not(.mat-tab-disabled):focus {
        background-color: unset;
    }
}
mat-tab-group.mat-mdc-tab-group {
    .mdc-tab__text-label {
        padding: 0;
        margin: 0 24px;
    }
}
@media (max-width: $sm-threshold) {
    mat-tab-group.mat-mdc-tab-group {
        .mdc-tab__text-label {
            margin: 0 12px;
        }
    }
}
mat-accordion.customized {
    mat-expansion-panel,
    .mat-expansion-panel:not([class*="mat-elevation-z"]) {
        box-shadow: none;
        mat-expansion-panel-header.mat-expansion-panel-header {
            padding: 0 12px;
            font-size: 16px;
            font-family: $font-family;
        }
    }
}
mat-radio-group.mat-radio-group.customized {
    mat-radio-button.mat-radio-button.mat-radio-checked {
        .mat-radio-inner-circle {
            transform: scale(0.9);
        }
        .mat-radio-label {
            .mat-radio-container {
                width: 15px;
                height: 15px;
                .mat-radio-outer-circle {
                    width: 15px;
                    height: 15px;
                    border-width: 0px;
                }
            }
        }
    }
}
mat-tab-group.mat-mdc-tab-group.product-request-icons > .mat-mdc-tab-header {
    .mdc-tab__text-label-content::before {
        font-family: hexa-icons !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        line-height: 24px;
        margin-right: 10px;
    }
    .mdc-tab__text-label:first-child {
        .mdc-tab__text-label-content::before {
            content: "\f12a";
        }
    }
    .mdc-tab__text-label:nth-child(2) {
        .mdc-tab__text-label-content::before {
            content: "\f10d";
        }
    }
    .mdc-tab__text-label:nth-child(3) {
        .mdc-tab__text-label-content::before {
            content: "tune";
            font-family: "Material Icons" !important;
        }
    }
    .mdc-tab__text-label:nth-child(4) {
        .mdc-tab__text-label-content::before {
            content: "panorama";
            font-family: "Material Icons" !important;
        }
    }
    .mat-tab-disabled {
        display: none;
    }
}

mat-radio-group.mat-radio-group.customized {
    mat-radio-button.mat-radio-button {
        .mat-radio-inner-circle {
            width: 15px;
            height: 15px;
        }
        .mat-radio-label {
            font-size: 13px;
            .mat-radio-container {
                width: 15px;
                height: 15px;
                .mat-radio-outer-circle {
                    width: 15px;
                    height: 15px;
                    border-width: 1px;
                }
            }
        }
    }
}

mat-tab-group.mat-mdc-tab-group.no-marg-first {
    .mdc-tab__text-label:first-child {
        padding: 0;
        margin: 0;
    }
}

body.resource-dropdown-open {
    .cdk-overlay-pane {
        width: 255px !important;
    }
}
body.resource-dropdown-open-m {
    .cdk-overlay-pane {
        width: 277px !important;
    }
}

.no-bottom-border {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        .mdc-line-ripple::before {
            border-bottom-color: transparent;
        }
    }
}
mat-chip-listbox.customized, mat-chip-grid.customized {
    // mat-chip.mat-chip.mat-standard-chip {
    //     padding: 1px 7px 1px 8px;
    //     font-weight: 100;
    //     min-height: 24px;
    //     .icon-close {
    //         font-size: 11px;
    //         padding-left: 5px;
    //     }
    //     .icon-close:before {
    //         vertical-align: bottom;
    //     }
    // }
    mat-chip.mat-chip.mat-standard-chip, mat-chip-row.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: $lead2;
        color: $contrast-text;
        border: solid 1px $lead2;
        .mdc-evolution-chip__text-label, .mdc-evolution-chip__icon--trailing {
            color: $contrast-text;
        }
        .mat-mdc-chip-action-label {
            font-size: $def-font-size;
        }
    }
    .mat-chip-list-wrapper {
        margin: -2px;
        input.mat-input-element.mat-chip-input {
            border: solid 1px;
            border-radius: 50px;
            text-indent: 8px;
            padding: 2px 0;
            font-weight: 100;
            font-size: 14px;
            background-color: $lead2;
            color: $contrast-text;
            height: 20px;
        }
        // input.mat-input-element.mat-chip-input::placeholder,
        // input.mat-input-element.mat-chip-input:-ms-input-placeholder,
        // input.mat-input-element.mat-chip-input::-ms-input-placeholder {
        //     color: $contrast-color !important;
        //     opacity: 1 !important;
        //     display: unset !important;
        //     visibility: unset !important;
        // }
    }
}

mat-button-toggle-group.mat-button-toggle-group-appearance-standard.customized {
    border-radius: 50px;
    box-shadow: unset;
    border: solid 1px;
    .mat-button-toggle-appearance-standard {
        color: $contrast-color;
        background-color: $contrast-color;
        .mat-button-toggle-label-content {
            transition-duration: $default-duration;
            color: $box-background;
            box-shadow: 1px 0px 0px $contrast-color;
            line-height: 23px;
            padding: 0 10px;
            min-width: 15px;
            text-transform: capitalize;
        }
    }
    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
        .mat-button-toggle-label-content {
            // background-color: $contrast-color;
            color: $secondary;
        }
    }
    .mdc-button-toggle:last-child {
        box-shadow: unset;
    }
}

mat-button-toggle-group.mdc-button-toggle-group.customized.invert {
    .mdc-button-toggle {
        background-color: $contrast-color;
        color: $box-background;
    }
    .mdc-button-toggle.mdc-button-toggle-checked {
        background-color: $contrast-color;
        color: $secondary;
    }
    // .mdc-button-toggle-label-content {
    //     background-color: $contrast-color;
    //     color: $box-background;
    // }
}

// .chip-list-wrap.mat-mdc-form-field {
//     .mat-mdc-form-field-label {
//         top: -0.74375em
//     }
// }

.chip-list-wrap.mat-mdc-form-field:not(.mat-focused) {
    .mat-mdc-form-field-label {
        text-indent: 10px;
        line-height: 20px;
    }
}

mat-form-field.no-blur-bottom-border,
mat-form-field.no-blur-bottom-border.mat-mdc-form-field {
    .mat-mdc-form-field-underline {
        background-color: transparent;
    }
}
mat-form-field.invert {
    .mat-mdc-form-field-label-wrapper {
        .mat-mdc-form-field-label {
            color: $box-background;
        }
    }
}
.min-datepicker.mat-mdc-form-field-has-icon-suffix {
    .mat-mdc-text-field-wrapper {
        padding: 0;
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
        width: 30px;
        height: 30px;
        padding: 2px 0 0;
        .mat-mdc-button-touch-target {
            width: 30px;
            height: 30px;
        }
    }
}
app-round-checkbox {
    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 8px 6px;
    }
}

.min-datepicker {
    .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
        padding-top: 0;
        padding-bottom: 0;
    }
    .mat-mdc-form-field-infix {
        width: 58px;
        padding: 0;
        min-height: 24px;
    }
    .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
        font-size: 11px;
        line-height: 15px;
    }
    .mat-mdc-form-field-label {
        font-size: 12px;
        line-height: 29px;
    }
}
.mat-mdc-form-field.mat-mdc-form-field-disabled.min-datepicker,
.mat-mdc-form-field.mat-mdc-form-field-disabled.no-disabled {
    .mat-mdc-form-field-underline {
        background-size: unset;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 1000%);
    }
    .mat-input-element:disabled {
        color: inherit;
    }
}
app-text-editor.customized {
    .ql-toolbar.ql-snow {
        border-bottom: none;
    }
    .ql-disabled {
        .ql-editor {
            padding: 0;
        }
    }
}
app-text-editor.small-images {
    .ql-editor {
        img {
            max-height: 10vh;
        }
    }
}
app-text-editor {
    display: block;

    .ql-disabled {
        .ql-editor {
            padding: 0;
        }
    }

    .ql-editor {
        img {
            max-width: 100%;
        }
    }

    .click-img {
        .ql-editor {
            img {
                cursor: pointer;
                box-shadow: 0px 2px 15px -1px rgba(0, 0, 0, 0.1);
                max-width: 95%;
                padding: 1.5%;
            }
        }
    }
    .ql-showHtml:after {
        content: "</>";
        font-weight: 700;
    }
}

ul.normalize {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
    }
}

.h1 {
    font-size: 16px;
    font-weight: 600;
    color: $contrast-color;
    text-transform: capitalize;
}

.h2 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: $contrast-color;
    text-transform: uppercase;
}

app-text-editor {
    .ql-editor {
        img {
            max-width: 100%;
        }
    }
}

.round {
    border-radius: 50%;
}

.h2.gray2 {
    color: $gray2;
}

.gray1 {
    color: $gray1;
}

.gray3 {
    color: $gray3;
}

mat-option.disabled {
    .mat-option-text {
        opacity: 0.4;
    }
}

.option-img {
    position: absolute;
    right: 5px;
    height: 47px;
    width: 47px;
    object-fit: cover;
}

.material,
.option-img.material {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.gray4-bg {
    background-color: $gray4;
}

.text1 {
    font-size: 14px;
    color: $contrast-color;
}

.text2 {
    font-size: 13px;
    color: $contrast-color;
}

.text2-bold {
    font-size: 13px;
    color: $contrast-color;
    font-weight: bold;
}

.text3 {
    font-size: 13px;
    color: $gray2;
}

.text4 {
    font-size: 13px;
    color: $gray2;
}

.text5 {
    font-size: 10px;
    color: $gray2;
}

.mat-mdc-form-field-short .mat-mdc-form-field-infix {
    width: 95px;
}
// .mat-mdc-form-field .mat-mdc-form-field-label{
//     color: $contrast-text;
// }
app-retailers-autocomplete.invert {
    mat-form-field {
        .mat-mdc-form-field-label-wrapper {
            .mat-mdc-form-field-label {
                color: $box-background;
            }
        }
        input {
            background-color: $box-background;
            color: $contrast-gray;
            border-radius: 5px;
            font-size: 17px;
            text-indent: 15px;
        }
    }
}

app-refine-results {
    app-retailers-autocomplete {
        // input {
        //     height: 22px;
        // }
        // input:focus-visible,
        // input:focus {
        //     outline: 2px solid $secondary;
        //     // border: 2px solid $secondary;
        //     //   height: 20px;
        // }
        .mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
            background-color: transparent;
        }
        .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix, .mdc-text-field {
            padding: 0;
            min-height: unset;
            margin: 0 -2px;
            input {
                border: solid 2px transparent;
                width: calc(100% - 4px);
                transition-duration: $default-duration;
                line-height: 18px;
            }
            input:focus-visible,input:focus {
                border: solid 2px $secondary;
            }
        }
        .mdc-line-ripple {
            display: none;
        }
    }
}

mat-form-field.warn-ph {
    .mat-mdc-form-field-label {
        color: $warn;
    }
    .mat-mdc-form-field-underline {
        background-color: $warn;
    }
}

.products-data {
    .image-data {
        width: $file-diameter;
        height: $file-diameter;
        display: inline-block;
        margin-left: 35px;
        border: solid 1px $gray5;
        position: relative;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .overlay {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.85);
            width: 100%;
            height: 100%;
            opacity: 0;
            transition-duration: $default-duration;
            z-index: 10;
            button.mdc-icon-button {
                color: $box-background;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                width: 50%;
            }
            .delete-image {
                left: 0;
            }
            .link-image {
                right: 0;
            }
        }
        .pin {
            transition-duration: $default-duration;
            opacity: 0;
            position: absolute;
            background-color: $box-background;
            padding: 3px;
            top: -15px;
            right: -13px;
            border-radius: 50%;
            z-index: 20;
            height: 28px;
            button.mdc-icon-button {
                background-color: $secondary;
                width: 28px;
                height: 28px;
                line-height: 34px;
                .mdc-button-wrapper {
                    line-height: 32px;
                    i {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .image-data:hover {
        .overlay,
        .pin {
            opacity: 1;
        }
    }
    .image-data:hover,
    .image-data.pinned {
        .pin {
            opacity: 1;
        }
    }
}

.products-data.disabled {
    .image-data .overlay .link-image {
        right: 25%;
    }
}

.hide-sm,
.hide-sm.mat-mdc-mini-fab,
.hide-sm.mat-divider {
    display: none;
}

.viewport-threshold {
    z-index: 1;
    opacity: 0.05;
    position: fixed;
}

@media (min-width: $sm-threshold) {
    .hide-gt-sm {
        display: none !important;
    }
    .hide-sm,
    .hide-sm.mat-mdc-mini-fab,
    .hide-sm.mat-divider {
        display: inherit;
    }
}
@media (max-width: $sm-threshold) {
    body.overflow-sm {
        overflow-y: auto;
    }
}
re-captcha > div {
    margin: auto;
}

.fx-fill {
    .mat-mdc-form-field-infix {
        width: 100%;
    }
}

.fade-in {
    animation-name: fadeIn;
    animation-duration: $slow-duration;
}

.tutorial-inner {
    transform: scaleX(0.9);
    letter-spacing: 2px;
    font-family: "Open Sans", open-sans;
}

.blink {
    animation: blink-animation 1.5s alternate infinite ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes blink-animation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.hide-desktop {
    display: block;
}

@media (min-width: $sm-threshold) {
    .hide-desktop,
    .hide-desktop.mdc-icon-button {
        display: none;
    }
}

.align-self-center {
    align-self: center;
}

.disp-flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

.none-draggable-selectable {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.flex-1 {
  flex: 1;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
    justify-content: flex-start;
}

.flex-gap-24 {
  gap: 24px;
}

.cursor-grab {
    cursor: grab;
}

.cursor-grabbing {
    cursor: grabbing;
}
.flex-1 {
    flex: 1;
}

.flex-end {
    justify-content: flex-end;
}

.flex-start {
    justify-content: flex-start;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-gap-24 {
    gap: 24px;
}
button {
    cursor: pointer;

    &.no-style {
        appearance: none;
        border: 0;
        background: transparent;
    }
}

.sharpen1 {
    filter: url(#Sharpen1);
}
.sharpen2 {
    filter: url(#Sharpen2);
}
.sharpen3 {
    filter: url(#Sharpen3);
}
.menu-img-btn.mat-mdc-menu-item {
    height: 114px;
    width: 150px;
    border-radius: 50%;
    transition-duration: $default-duration;

    img {
        height: 114px;
        width: 114px;
        border-radius: 50%;
        object-fit: cover;
    }
}
.warn {
    color: $warn3;
}

button.mat-mdc-menu-item.active {
    background-color: rgba(120, 120, 120, 0.25);
}

body {
    button.mat-raised-button {
        border-radius: 5px;
    }

    .prime_button {
        height: 40px;
        width: 192px;
        border-radius: 5px;
        color: $contrast-text;
        background: linear-gradient(93.12deg, #506992 0%, #21C5BF 172.21%);
        font-family: $font-family;
        text-transform: uppercase;
    }

    .second_button {
        height: 40px;
        width: 192px;
        background-color: $contrast-text;
        border:1px solid $brand;
        color:  $brand;
        border-radius: 5px;
        font-size: 13px;
        font-family: $font-family;
        text-transform: uppercase;
    }

    .second_button.slim {
    height: 32px;
    }

    .third_button {
        background-color: $secondary;
        color: $contrast-text;
        border-radius: 5px;
        height: 32px;
        border: 0px;
        text-transform: uppercase;
        width: 192px;
        cursor: pointer;
        line-height: 28px;
        font-size: 13px;
        font-family: $font-family;
    }

    .third_button:disabled {
        cursor: default;
    }

    .prime_button.mat-raised-button.mdc-button-disabled{
        opacity: 0.4;
        color: $contrast-text;
    }
}


@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@include keyframes(scale-fade) {
    from   { opacity: 0; transform: scale(0); }
    to { opacity: 1; transform: scale(1); }
}

@include keyframes(fade-in) {
    from   { opacity: 0; }
    to { opacity: 1; }
}

@keyframes quick-view-enter-order {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: $quick-view-height-order;
        opacity: 1;
    }
}

@keyframes quick-view-exit-order {
    from {
        height: $quick-view-height-order;
        opacity: 1;
    }
    to {
        height: 0;
        opacity: 0;
    }
}

@keyframes quick-view-enter {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: $quick-view-height;
        opacity: 1;
    }
}

@keyframes quick-view-exit {
    from {
        height: $quick-view-height;
        opacity: 1;
    }
    to {
        height: 0;
        opacity: 0;
    }
}