$app-max-width:      1320px;
$default-duration:   0.3s;
$slow-duration:      0.5s;
$box-background:    #fff;
$box-alt-background:#eee;
$body-background:   #F7FAFC;
$contrast-color:    #1F1F33;
$contrast-text:     #fff;
$brand:             #1F1F33;
$lead2:             #546994;
$lead3:             #3D90A5;
$lead4:             #02BDB5;
$primary:           #34BDB7;
$primary-outline:   #55BAB4;
$secondary:         #00BDB5;
$tertiary:          #ea80fc;
$primary-hover:     rgba(0, 0, 0, 0.04);
$warn:              #C61D51;
$warn3:             #F22331;
$accent:            #111010;
$warn2:             #ffca00;
$warn-accent:       #FF9300;
$header-height:      77px;
$header-padd:        25px;
$xs-threshold:       599px;
$sm-threshold:       959px;
$md-threshold:       1279px;
$lg-threshold:       1919px;
$xl-threshold:       5000px;
$gray:              rgba(0, 0, 0, 0.54);
$gray1:             #3b3b3b;
$gray2:             #737373;
$gray3:             #aaaaaa;
$gray4:             #eeeeee;
$gray5:             #dcdcdc;
$gray6:             #E2E2E2;
$gray7:             #6A6A6A;
$gray8:             #949494;
$gray9:             #D2D2D6;
$gray10:            rgba(0, 0, 0, 0.42);
$gray11:            #C0C0D6;
$on-button:         rgba(255, 255, 255, 0.5);
$grayout:           #C0C0C0;
$contrast-gray:     #1F1F33;
$disabled:          rgba(0, 0, 0, 0.26);
$side-bar-width:     300px;
$small-box-width:    550px;
$small-box-height:   550px;
$medium-box-width:   880px;
$file-diameter:      114px;
$sidebar-width:      240px;
$font-family:        'Open Sans', open-sans;
$default-padd:       40px;
$quick-view-height: 175px;
$quick-view-height-order: 150px;
$shadow-color:      rgba(44, 47, 52, 0.12);
$xs-threshold:      600px;
$sm-threshold:      960px;
$scrollbar-width:   6px;
$mobileScreen: "(max-width: 599px)";
$tabletScreen: "(min-width: 600px) and (max-width: 959px)";
$link-color: #02D6CD;
$def-font-size: 13px;